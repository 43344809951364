require('../vendor/promise-polyfill.min.js').polyfill();

const axios = require('../vendor/axios.min.js');

const rol_search = {
    search_results_container: null,
    text_input_value: '',
    api_url_piece: '/api/v1',
    search_param: null,
    current_result_type: 'courses',
    currentResults: {
        courses: null,
        resources: null,
        tags: null,
        authors: null,
    },
    courses_page: 1,
    resources_page: 1,
    timeout_fadeout: null,
    timeout_fadein: null,
    no_results: 'Sorry, there are no results for that search term...',
    error: 'Sorry, we hit an error trying to fetch your results. Please refresh the page to try again.',

    // Tag & author filters
    current_tag_author_arrays: {
        tags: [],
        author: [],
    },
    current_tag_author_params: {
        tags: '',
        author: '',
    },
    filter_holders: {
        tags: null,
        authors: null,
    },
    filter_title_keys: {
        tags: 'title',
        authors: 'full_name',
    },

    setup() {
        const search_text_input = document.getElementById('search__text');
        rol_search.filter_holders.tags = document.getElementById('search__tags-filter');
        rol_search.filter_holders.authors = document.getElementById('search__authors-filter');
        rol_search.search_results_container = document.querySelector('.search__results');

        if (rol_search.search_results_container && search_text_input) {
            // Add event listeners to text input, pagination buttons
            search_text_input.addEventListener('keydown', rol_search.textInputUpdate);
            rol_search.setupPaginationEvents();

            // Change result type & results container on switching tabs
            rol_search.setupTabs();
        }
    },

    setupTabs() {
        const search_tab_btns = document.getElementsByClassName('tabs__button-search');

        for (let i = 0; i < search_tab_btns.length; i += 1) {
            const this_tab_btn = search_tab_btns[i],
                connected_result_type = this_tab_btn.getAttribute('data-search-result-type'),
                connected_tab = this_tab_btn.getAttribute('aria-controls');

            search_tab_btns[i].addEventListener('click', () => {
                rol_search.searchTabClick(connected_result_type, connected_tab);
            });
        }
    },

    searchTabClick(connected_result_type, connected_tab) {
        // On click, as well as standard tab switching, change result type which will be
        // searched for. Check for results in new tab.
        if (connected_result_type !== rol_search.current_result_type) {
            rol_search.current_result_type = connected_result_type;
            rol_search.search_results_container = document.querySelector(`#${connected_tab} .search__results`);

            rol_search.getNewCourseResourceResults(false);
        }
    },

    textInputUpdate() {
        setTimeout(() => {
            const new_value = this.value;

            if (new_value !== '' && new_value !== rol_search.text_input_value) {
                // Update search params
                rol_search.search_param = new_value;
                rol_search.resetTagsAuthorsPage();
                rol_search.getNewCourseResourceResults(true);
                rol_search.getNewTagsAuthorsResults('tags');
                rol_search.getNewTagsAuthorsResults('authors');
            }
        }, 0);
    },

    getNewTagsAuthorsResults(result_type) {
        const search_url = `${rol_search.api_url_piece}/${result_type}`;

        axios.get(`${search_url}?search=${rol_search.search_param}`)
            .then((response) => {
                rol_search.fillNewTagsAuthorsResults(response, result_type);
            })
            .catch(rol_search.showErrorMessage);
    },

    getNewCourseResourceResults(allow_tab_switch_on_empty, manual_result_type) {
        const { tags, author } = rol_search.current_tag_author_params,
            current_result_type = manual_result_type || rol_search.current_result_type,
            search_url = `${rol_search.api_url_piece}/${current_result_type}/`,
            divider = (author !== '' && tags !== '') ? '&' : '',
            multi_params = `${tags}${divider}${author}`,
            page_param = rol_search.current_result_type === 'courses' ? rol_search.courses_page
                : rol_search.resources_page;

        // If tags or authors have been selected, don't also filter by search term as all
        // results connected to that tag/author should be returned
        let search_param_to_use = `&search=${rol_search.search_param}`;
        if (multi_params !== '') {
            search_param_to_use = '';
        }

        axios.get(`${search_url}?page=${page_param}${search_param_to_use}&${multi_params}`)
            .then((response) => {
                if (manual_result_type) {
                    rol_search.nonActiveTabResults(response);
                } else {
                    rol_search.newCourseResourceResults(response, allow_tab_switch_on_empty);
                }
            })
            .catch(rol_search.showErrorMessage);
    },

    showErrorMessage() {
        // reset back to page 1, clear results, show error message
        rol_search.resetTagsAuthorsPage();
        rol_search.search_results_container.innerHTML = '';
        rol_search.showMessage(rol_search.error);
    },

    resetTagsAuthorsPage() {
        rol_search.courses_page = 1;
        rol_search.resources_page = 1;
        rol_search.current_tag_author_arrays.tags = [];
        rol_search.current_tag_author_arrays.author = [];
        rol_search.current_tag_author_params.tags = '';
        rol_search.current_tag_author_params.author = '';
    },

    newCourseResourceResults(response, allow_tab_switch_on_empty) {
        const current_results_by_type = rol_search.currentResults[rol_search.current_result_type];
        // Only change html if results have changed (as request is made on each keystroke)
        if (JSON.stringify(current_results_by_type) !== JSON.stringify(response.data.results)) {
            // Fade out current results
            rol_search.search_results_container.classList.add('fade-out');
            rol_search.clearTimeouts();

            // If no results, check other tab
            // (allow...) prevents this happening repeatedly / on tab click
            if (response.data.results.length === 0 && allow_tab_switch_on_empty) {
                rol_search.getOtherResults();
            }

            // Switch results etc. Only update 'currentResults' when they are on-page
            rol_search.timeout_fadeout = setTimeout(() => {
                rol_search.currentResults[rol_search.current_result_type] = response.data.results;
                rol_search.search_results_container.innerHTML = '';
                rol_search.fillNewCourseResouceResults(response);
                rol_search.showHideElementsMessages(response.data);
                rol_search.showHidePagination(response.data);
            }, 300);

            // Fade back in
            rol_search.timeout_fadein = setTimeout(() => {
                rol_search.search_results_container.classList.remove('fade-out');
            }, 350);
        }
    },

    clearTimeouts() {
        if (rol_search.timeout_fadeout !== null) {
            clearTimeout(rol_search.timeout_fadeout);
        }

        if (rol_search.timeout_fadein !== null) {
            clearTimeout(rol_search.timeout_fadein);
        }
    },

    fillNewCourseResouceResults(response) {
        for (let i = 0; i < response.data.results.length; i += 1) {
            const new_item = document.createElement('div'),
                this_data = response.data.results[i];

            new_item.classList.add('course-resource__card');
            new_item.innerHTML = rol_search.itemInnerHtml({
                label: rol_search.current_result_type === 'courses' ? 'Course' : 'Resource',
                item_xlink: rol_search.current_result_type === 'courses' ? '#courses' : '#resources',
                this_data,
            });

            rol_search.search_results_container.appendChild(new_item);
        }
    },

    getOtherResults() {
        // If there are no results for e.g. courses, check if resources has results
        // and automatically switch tabs
        const other_type = rol_search.current_result_type === 'courses' ? 'resources' : 'courses';
        rol_search.getNewCourseResourceResults(false, other_type);
    },

    nonActiveTabResults(response) {
        // If this function is called there are results only for the non-active tab
        if (response.data.count > 0) {
            const other_type = rol_search.current_result_type === 'courses' ? 'resources' : 'courses',
                other_tab = document.querySelector(`.tabs__button-search[aria-controls=search-tab-${other_type}]`);

            // Switch tab
            if (other_tab) {
                other_tab.click();
            }
        }
    },

    showHideElementsMessages(data) {
        // When no results, grey out filters, show 'no results' message
        // Show filters & tabs continually after first search with results
        const filters = document.getElementById('search__filters'),
            tabs = document.getElementById('tabs-group-search');

        if (data.count === 0) {
            rol_search.showMessage(rol_search.no_results);
            filters.classList.add('grey-out');
        } else {
            filters.classList.remove('grey-out');
            filters.classList.remove('search__hide');
            tabs.classList.remove('search__hide');
        }
    },

    showMessage(text_content) {
        const new_message = document.createElement('p');

        new_message.innerHTML = text_content;
        rol_search.search_results_container.appendChild(new_message);
    },

    itemInnerHtml({ label, item_xlink, this_data }) {
        // Construct new element
        const course_only_html = label === 'Resource' ? ''
                : `<span class="course-resource__divider"></span><span>${this_data.total_parts} Parts</span>`,
            image_style = this_data.background_image_thumbnail === null ? ''
                : `style="background-image: url(${this_data.background_image_thumbnail})"`;

        const innerHtml = `
            <a href="/courses/${this_data.slug}">
                <div class="course-resource__card-info" >
                    <div class="course-resource__card-image" ${image_style}></div>
                    <div class="course-resource__text-snippet">
                        <h4>${this_data.title}</h4>
                        <div class="course-resource__text-overflow">
                            <p>${this_data.short_description}</p>
                        </div>
                    </div>
                </div>

                <div class="course-resource__parts-bookmarks">
                    <div class="course-resource__label">
                        <svg aria-hidden="true"><use xlink:href="${item_xlink}" /></svg>
                        <span class="capitalize">${label}</span>
                        ${course_only_html}
                    </div>

                    <div class="course-resource__bookmarks">
                      <svg aria-hidden="true"><use xlink:href="#bookmarks" /></svg>
                      <span>${this_data.total_bookmarks}</span>
                    </div>
                </div>
            </a>
        `;

        return innerHtml;
    },

    setupPaginationEvents() {
        const result_area_ids = ['search-tab-courses', 'search-tab-resources'],
            pagination_buttons = ['search-prev', 'search-next'];

        for (let i = 0; i < result_area_ids.length; i += 1) {
            for (let button_i = 0; button_i < pagination_buttons.length; button_i += 1) {
                const button = document.querySelector(`#${result_area_ids[i]} .${pagination_buttons[button_i]}`);

                if (button) {
                    button.addEventListener('click', () => {
                        const direction = button_i === 0 ? -1 : 1,
                            page_param = i === 0 ? 'courses_page' : 'resources_page';

                        rol_search[page_param] += direction;
                        rol_search.getNewCourseResourceResults(false);
                    });
                }
            }
        }
    },

    showHidePagination(response_data) {
        const prev_element = rol_search.search_results_container.parentNode.querySelector('.search-prev'),
            next_element = rol_search.search_results_container.parentNode.querySelector('.search-next');

        rol_search.showHidePrevNext(response_data.previous, prev_element);
        rol_search.showHidePrevNext(response_data.next, next_element);
    },

    showHidePrevNext(data_link, element) {
        if (element && data_link) {
            element.classList.add('show');
        } else if (element) {
            element.classList.remove('show');
        }
    },

    fillNewTagsAuthorsResults(response, result_type) {
        const this_holder = rol_search.filter_holders[result_type],
            title_key = rol_search.filter_title_keys[result_type],
            current_results_by_type = rol_search.currentResults[result_type];

        if (JSON.stringify(current_results_by_type) !== JSON.stringify(response.data)) {
            rol_search.currentResults[result_type] = response.data;
            this_holder.innerHTML = '';

            if (response.data.length > 0) {
                for (let i = 0; i < response.data.length; i += 1) {
                    const this_data = response.data[i],
                        title = this_data[title_key],
                        filter_type = result_type === 'authors' ? 'author' : 'tags',
                        new_button = rol_search.makeTagAuthorButton(
                            result_type, title, filter_type, this_data,
                        );

                    this_holder.appendChild(new_button);
                }
            }
        }
    },

    makeTagAuthorButton(result_type, title, filter_type, this_data) {
        const new_button = document.createElement('button');

        new_button.setAttribute('aria-label', `Search by ${result_type}: ${title}`);
        new_button.innerHTML = title;
        new_button.classList.add('search__filter-button');
        new_button.classList.add('tags-block__tag');
        new_button.addEventListener('click', (e) => {
            rol_search.toggleFilter(e, this_data.id, filter_type);
        });

        return new_button;
    },

    toggleFilter(e, tag_id, filter_type) {
        const this_btn = e.target,
            is_active = this_btn.classList.contains('active'),
            this_filters_array = rol_search.current_tag_author_arrays[filter_type];

        if (is_active) {
            const index_filter = this_filters_array.indexOf(tag_id);
            this_btn.classList.remove('active');

            if (index_filter !== -1) {
                this_filters_array.splice(index_filter, 1);
            }
        } else {
            this_btn.classList.add('active');
            this_filters_array.push(tag_id);
        }

        rol_search.filterArrayToString(this_filters_array, filter_type);

        // Reset back to page one for different search
        rol_search.courses_page = 1;
        rol_search.resources_page = 1;
        rol_search.getNewCourseResourceResults(true);
    },

    filterArrayToString(this_filters_array, filter_type) {
        // turn an array of tags to filter by into correctly formatted string
        let new_params_string = '';

        for (let i = 0; i < this_filters_array.length; i += 1) {
            const extra_param_key = i === 0 ? `${filter_type}=` : `&${filter_type}=`;

            new_params_string += `${extra_param_key}${this_filters_array[i]}`;
        }

        rol_search.current_tag_author_params[filter_type] = new_params_string;
    },
};

module.exports = rol_search;
