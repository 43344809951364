const rol_page_reload = {
    setup() {
        const reload_links = document.querySelectorAll('a.page-reload-link');

        for (let i = 0; i < reload_links.length; i += 1) {
            const this_link = reload_links[i],
                this_href = this_link.href;

            this_link.id = `reload_link_${i}`;

            this_link.addEventListener('click', (e) => {
                e.preventDefault();
                rol_page_reload.fadeOutContent();
                if (rol_page_reload.sessionStorageAvailable) {
                    rol_page_reload.saveFocusElement(this_link.id);
                    rol_page_reload.savePageScrollPos();
                }

                window.location.replace(this_href);
            });
        }

        // Check to see if info about focused element & scroll pos is stored
        rol_page_reload.checkStoredInfo();

        // Fade in messages
        rol_page_reload.fadeInMessage();
    },

    fadeInMessage() {
        const messages = document.querySelector('.messages');

        if (messages) {
            messages.classList.add('message-show');
        }
    },

    fadeOutContent() {
        const body = document.querySelector('body');

        if (body) {
            body.classList.add('reload-fade');
        }
    },

    saveFocusElement(focused_link_id) {
        window.sessionStorage.setItem('rol_focused_el', focused_link_id);
    },

    savePageScrollPos() {
        const scroll_pos = window.pageYOffset || document.documentElement.scrollTop;
        window.sessionStorage.setItem('rol_scroll_top', scroll_pos);
    },

    checkStoredInfo() {
        if (rol_page_reload.sessionStorageAvailable) {
            const scroll_top = window.sessionStorage.getItem('rol_scroll_top'),
                focus_el = window.sessionStorage.getItem('rol_focused_el');

            if (scroll_top) {
                document.documentElement.scrollTop = scroll_top;
                window.sessionStorage.setItem('rol_scroll_top', null);
            }

            setTimeout(() => {
                if (focus_el && document.querySelector(`#${focus_el}`)) {
                    document.querySelector(`#${focus_el}`).focus();
                    window.sessionStorage.setItem('rol_focused_el', null);
                }
            }, 0);
        }
    },

    sessionStorageAvailable() {
        try {
            const storage = window.sessionStorage,
                x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            return false;
        }
    },
};

module.exports = rol_page_reload;
