const rol_form = {
    profile_image_max_size_kb: 1024,

    setup() {
        rol_form.addCustomCheckboxes();
        rol_form.addCustomRadios();
        rol_form.handleSingleCheckboxes();
        rol_form.profilePicUploaderEvents();

        rol_form.customButtonEvents({
            customButtonId: 'profile-pic__upload',
            originalElementId: 'id_photo',
            doSubmit: false,
        });

        rol_form.customButtonEvents({
            customButtonId: 'profile-pic__clear',
            originalElementId: 'photo-clear_id',
            doSubmit: true,
        });
    },

    addCustomCheckboxes() {
        const checkboxes = document.querySelectorAll('.control-group input[type="checkbox"]');

        rol_form.addElementsAfter({
            existingEls: checkboxes,
            newClassName: 'custom-checkbox',
        });
    },

    addCustomRadios() {
        const radios = document.querySelectorAll('.control-group input[type="radio"]');

        rol_form.addElementsAfter({
            existingEls: radios,
            newClassName: 'custom-radio',
        });
    },

    addElementsAfter({ existingEls, newClassName }) {
        // For an array of existing elements, add custom els immediately after
        // with specified class
        for (let i = 0; i < existingEls.length; i += 1) {
            const this_el = existingEls[i],
                custom_el = document.createElement('div');
            custom_el.classList.add(newClassName);

            // Add the custom checkbox directly after the input element
            if (this_el.nextSibling) {
                this_el.parentNode.insertBefore(
                    custom_el, this_el.nextSibling,
                );
            } else {
                this_el.parentNode.appendChild(custom_el);
            }
        }
    },

    handleSingleCheckboxes() {
        // Where crispy forms has a different layout & input & custom are
        // not children of the label element
        const custom_checkboxes = document.getElementsByClassName('custom-checkbox');

        for (let i = 0; i < custom_checkboxes.length; i += 1) {
            custom_checkboxes[i].addEventListener('click', () => {
                const this_prev_sibling_id = custom_checkboxes[i].previousSibling.id,
                    this_next_sibling = custom_checkboxes[i].nextElementSibling;

                if (this_next_sibling) {
                    const label = this_next_sibling.getAttribute('for');

                    if (label === this_prev_sibling_id) {
                        this_next_sibling.click();
                    }
                }
            });
        }
    },

    customButtonEvents({ customButtonId, originalElementId, doSubmit }) {
        const custom_add_button = document.getElementById(customButtonId),
            target_input = document.getElementById(originalElementId),
            submit_input = document.getElementById('submit-id-submit');

        if (custom_add_button && target_input) {
            // Click on original (non-visible) inputs from custom replacements
            custom_add_button.addEventListener('click', () => {
                if (target_input) {
                    target_input.click();

                    if (doSubmit && submit_input) {
                        submit_input.click();
                    }
                }
            });
        }
    },

    profilePicUploaderEvents() {
        // Add custom handling on profile pic upload
        const uploader = document.getElementById('id_photo');

        if (uploader) {
            uploader.addEventListener('change', rol_form.fileSelectHandler);
        }
    },

    fileSelectHandler(e) {
        // Fetch FileList object
        const files = e.target.files || e.dataTransfer.files;
        // Process one File object
        rol_form.parseFile(files[0]);
    },

    parseFile(file) {
        const imageName = file.name,
            isGood = (/\.(?=gif|jpg|png|jpeg)/gi).test(imageName),
            preview_img = document.getElementById('profile-img-preview'),
            preview_holder = document.getElementById('profile-img__preview-holder'),
            help_message = document.getElementById('profile-pic__help');

        // Check for a correct extension and reasonable size
        if (isGood && file.size < rol_form.profile_image_max_size_kb * 1024) {
            // If ok, upload preview
            preview_img.src = URL.createObjectURL(file);
            preview_holder.classList.add('show');
            help_message.classList.remove('show');
        } else {
            // If not ok, clear input value
            preview_holder.classList.remove('show');
            help_message.classList.add('show');
            document.getElementById('id_photo').value = '';
        }
    },
};

module.exports = rol_form;
