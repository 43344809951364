const rol_tabs = {
    setup() {
        const tab_groups = document.getElementsByClassName('tabs__group');

        for (let i = 0; i < tab_groups.length; i += 1) {
            const tabs_holder = tab_groups[i],
                tab_buttons = tabs_holder.querySelectorAll('.tabs__button');

            for (let button_index = 0; button_index < tab_buttons.length; button_index += 1) {
                const associated_id = tab_buttons[button_index].getAttribute('aria-controls');

                // Set tab button click events by tab group
                tab_buttons[button_index].addEventListener('click', (e) => {
                    if (e.target.getAttribute('aria-expanded') !== true) {
                        rol_tabs.showHideTabs(tabs_holder, associated_id);
                    }
                });
            }
        }

        rol_tabs.checkStoredTabs();
    },

    showHideTabs(tabs_holder, associated_id) {
        const tab_group_buttons = tabs_holder.querySelectorAll('.tabs__button'),
            tab_group_contents = tabs_holder.querySelectorAll('.tabs__content');

        for (let i = 0; i < tab_group_buttons.length; i += 1) {
            const this_tab_contents = tab_group_contents[i];

            if (this_tab_contents) {
                const this_content_id = this_tab_contents.id;

                if (this_content_id === associated_id) {
                    tab_group_buttons[i].setAttribute('aria-expanded', true);
                    this_tab_contents.classList.remove('tabs__hide');
                } else {
                    tab_group_buttons[i].setAttribute('aria-expanded', false);
                    this_tab_contents.classList.add('tabs__hide');
                }
            }
        }

        rol_tabs.storeCurrentTab(associated_id);
        rol_tabs.forceSliderResize();
    },

    forceSliderResize() {
        // Resize event ensures the slider and slides are sized correctly
        const false_resize = document.createEvent('HTMLEvents');
        false_resize.initEvent('resize', true, false);
        window.dispatchEvent(false_resize);
    },

    storeCurrentTab(current_content_id) {
        // Store current content id for tab group on page
        // This will only work correctly for pages with a single tab group
        // - should new page tpes be added an update may be needed
        if (rol_tabs.sessionStorageAvailable) {
            window.sessionStorage.setItem('rol_current_tab', current_content_id);
        }
    },

    checkStoredTabs() {
        // Check for stored active tab value
        let tab_id = null;
        if (rol_tabs.sessionStorageAvailable) {
            tab_id = window.sessionStorage.getItem('rol_current_tab');
            const active_tab = document.getElementById(tab_id);

            if (active_tab) {
                let tabs_holder = active_tab.parentNode;

                if (!tabs_holder.classList.contains('tabs__group')) {
                    tabs_holder = active_tab.parentNode.parentNode;
                }
                rol_tabs.showHideTabs(tabs_holder, tab_id);
            } else {
                // If stored value doesn't match an element on the page
                rol_tabs.showFirstTab();
            }
        } else {
            rol_tabs.showFirstTab();
        }
    },

    showFirstTab() {
        // Show first tab to start
        const tab_groups = document.getElementsByClassName('tabs__group');

        for (let i = 0; i < tab_groups.length; i += 1) {
            const tabs_holder = tab_groups[i],
                tab_first_button = tabs_holder.querySelector('.tabs__button');

            if (tab_first_button) {
                const associated_id = tab_first_button.getAttribute('aria-controls');
                rol_tabs.showHideTabs(tabs_holder, associated_id);
            }
        }
    },

    sessionStorageAvailable() {
        try {
            const storage = window.sessionStorage,
                x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            return false;
        }
    },
};

module.exports = rol_tabs;
