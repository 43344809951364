const rol_form = require('./form.js'),
    rol_search = require('./search.js'),
    rol_sliders = require('./sliders.js'),
    rol_tabs = require('./tabs.js'),
    rol_medium_pages = require('./medium-pages.js'),
    rol_show_hide = require('./show_hide.js'),
    rol_page_reload = require('./page-reload.js'),
    rol_accessibility = require('./accessibility.js');

document.addEventListener('DOMContentLoaded', () => {
    // Setup forms
    rol_form.setup();

    // Setup search (if on search page)
    rol_search.setup();

    // Setup sliders used across site
    rol_sliders.setup();

    // Setup tabs
    rol_tabs.setup();

    // Add 'reading time' on detail pages
    rol_medium_pages.setup();

    // Setup show/hide toggles
    rol_show_hide.setup();

    // For links that reload the page (e.g. bookmark & unbookmark etc)
    // Return to the same scroll position
    rol_page_reload.setup();

    // Add accessibility listeners/functions
    rol_accessibility.setup();
});
