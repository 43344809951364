const rol_show_hide = {
    setup() {
        const toggles = document.querySelectorAll('.show-hide-toggle'),
            close_btns = document.querySelectorAll('.close-toggle');

        for (let i = 0; i < toggles.length; i += 1) {
            const this_btn = toggles[i],
                controlled_el_id = this_btn.getAttribute('aria-controls'),
                controlled_el = document.querySelector(`#${controlled_el_id}`);
            if (controlled_el) {
                toggles[i].addEventListener('click', () => {
                    rol_show_hide.toggleClick(controlled_el, this_btn);
                });
            }
        }

        for (let c = 0; c < close_btns.length; c += 1) {
            const close_btn = close_btns[c],
                controlled_el_id = close_btn.getAttribute('aria-controls'),
                controlled_el = document.querySelector(`#${controlled_el_id}`),
                related_toggle_id = close_btn.getAttribute('data-related-toggle'),
                related_toggle = document.querySelector(`#${related_toggle_id}`);

            if (controlled_el) {
                close_btns[c].addEventListener('click', () => {
                    controlled_el.classList.remove('show');

                    if (related_toggle) {
                        related_toggle.setAttribute('aria-expanded', 'false');
                    }
                });
            }
        }
    },

    toggleClick(controlled_el, this_btn) {
        const is_expanded = this_btn.getAttribute('aria-expanded') === 'true';

        if (is_expanded) {
            this_btn.setAttribute('aria-expanded', 'false');
            controlled_el.classList.remove('show');
        } else {
            this_btn.setAttribute('aria-expanded', 'true');
            controlled_el.classList.add('show');

            // Resize event ensures the slider and slides are sized correctly
            const false_resize = document.createEvent('HTMLEvents');
            false_resize.initEvent('resize', true, false);
            window.dispatchEvent(false_resize);
        }
    },
};

module.exports = rol_show_hide;
