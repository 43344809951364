const rol_accessibility = {
    setup() {
        window.addEventListener('keydown', rol_accessibility.handleFirstTab);
    },

    // Watch for user tabbing and enable focus styles if tab key pressed
    handleFirstTab(e) {
        if (e.keyCode === 9) {
            // the "I am a keyboard user" key
            document.body.classList.add('user-tabbing');
            window.removeEventListener('keydown', rol_accessibility.handleFirstTab);
        }
    },
};

module.exports = rol_accessibility;
