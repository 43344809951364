// https://pawelgrzybek.github.io/siema/
const Siema = require('../vendor/siema.min.js'),
    debounce = require('./debounce.js');

const rol_sliders = {
    std_slider_instances: [],

    home_theme_slider: null,
    home_theme_original_markup: null,
    home_theme_slider_is_siema: false,
    home_theme_slider_opts: {
        selector: '#siema__home-theme',
        perPage: {
            0: 2,
            401: 3,
            499: 4,
            767: 6,
        },
        duration: 450,
        easing: 'ease-in-out',
        onInit: () => { rol_sliders.setAriaAttributes(-1); },
        onChange: () => {
            rol_sliders.setAriaAttributes(-1);
        },
    },

    setup() {
        // Setup 'standard' sliders
        const std_sliders = document.querySelectorAll('.slider:not(.slider__special) ul');

        for (let i = 0; i < std_sliders.length; i += 1) {
            const this_slider = std_sliders[i],
                slider_id = `slider-${i}`,
                slider_parent = this_slider.parentNode,
                slider_slides = this_slider.querySelectorAll('li'),
                per_page_config = rol_sliders.setupStandardMultiAcross(slider_parent);

            this_slider.id = slider_id;
            this_slider.setAttribute('data-index', i);

            const this_instance = new Siema({
                selector: `#${slider_id}`,
                duration: 450,
                easing: 'ease-in-out',
                perPage: per_page_config,
                onInit: () => { rol_sliders.setAriaAttributes(i); },
                onChange: () => {
                    rol_sliders.setAriaAttributes(i);
                    rol_sliders.resetActiveButton(slider_parent, i);
                },
            });

            rol_sliders.std_slider_instances.push(this_instance);
            rol_sliders.setupButtons(i, slider_parent, slider_slides);
        }

        // Home - theme slider - check if the slider should be setup only for mobile
        // (user logged in, 'jump to' card in lefthand column on desktop), or normally
        rol_sliders.checkHomeThemeToggle();

        window.addEventListener('resize', rol_sliders.onResize);
    },

    onResize: debounce(() => {
        // (i = -1 is to include the special-case home-theme slider)
        for (let i = -1; i < rol_sliders.std_slider_instances.length; i += 1) {
            rol_sliders.setAriaAttributes(i);
        }
    }, 500),

    setupStandardMultiAcross(slider_parent) {
        // Standard multi-across will be 3 on desktop
        // (Home page theme slider is still handled separately - custom config)
        const slider_holder_classes = slider_parent.classList;
        let per_page_config = {};

        if (slider_holder_classes.contains('slider__multi-across')) {
            per_page_config = {
                499: 2,
                768: 3,
            };
        }

        return per_page_config;
    },

    setupButtons(slider_index, slider_parent, slider_slides) {
        const buttons = slider_parent.querySelector('.slider__buttons');

        for (let i = 1; i <= slider_slides.length; i += 1) {
            // All sliders - set index attribute per slide
            const slide_index = (i - 1),
                this_slide = slider_slides[slide_index];

            this_slide.setAttribute('data-slide-index', slide_index);

            // Add 'next' & 'prev' tab links on slides
            rol_sliders.addNextPrev(slider_index, this_slide, slide_index, slider_slides.length);

            // Sliders with 'dot' nav buttons
            if (buttons) {
                const new_button = document.createElement('button');
                new_button.setAttribute('aria-label', `Show slide ${i} of ${slider_slides.length}`);
                new_button.setAttribute('data-slide-index', slide_index);
                new_button.setAttribute('data-slider-index', slider_index);
                new_button.innerHTML = `<span>${i}</span>`;

                if (i === 1) {
                    new_button.classList.add('active');
                }

                buttons.appendChild(new_button);
                new_button.addEventListener('click', rol_sliders.sliderButtonClick);
            }
        }
    },

    sliderButtonClick() {
        const this_slider_index = this.getAttribute('data-slider-index'),
            this_slide_index = this.getAttribute('data-slide-index');

        if (this_slider_index && this_slide_index) {
            const this_slider_instance = rol_sliders.std_slider_instances[
                parseInt(this_slider_index, 10)
            ];

            this_slider_instance.goTo(parseInt(this_slide_index, 10));
        }
    },

    addNextPrev(slider_index, this_slide, slide_index, total_slides) {
        // Allow keyboard navigation through slider. Next / prev buttons will
        // show on focus only and not move focus away from current position in slides
        if (slide_index !== 0) {
            const prev_button = rol_sliders.setupNavBtn('Previous slide', 'slider__prev', slider_index);

            try { // Modern browsers
                this_slide.prepend(prev_button);
            } catch (e) { // IE11
                this_slide.appendChild(prev_button);
            }
        }

        if (slide_index !== total_slides - 1) {
            const next_button = rol_sliders.setupNavBtn('Next slide', 'slider__next', slider_index);
            this_slide.appendChild(next_button);
        }
    },

    setupNavBtn(aria_label, direction_class, slider_index) {
        const this_button = document.createElement('button');

        this_button.setAttribute('aria-label', aria_label);
        // IE11 requires class adding to be longhand!
        this_button.classList.add('focus-visible');
        this_button.classList.add(direction_class);
        this_button.addEventListener('click', (e) => {
            rol_sliders.nextPrevClick(e, slider_index);
        });

        return this_button;
    },

    nextPrevClick(e, slider_index) {
        const direction_next = e.target.classList.contains('slider__next'),
            this_slider_instance = slider_index === -1 ? rol_sliders.home_theme_slider
                : rol_sliders.std_slider_instances[parseInt(slider_index, 10)];

        if (direction_next) {
            this_slider_instance.next();
        } else {
            this_slider_instance.prev();
        }
    },

    resetActiveButton(slider_holder, slider_index) {
        setTimeout(() => {
            const this_slider_instance = rol_sliders.std_slider_instances[slider_index];

            if (slider_holder) {
                const button_set = slider_holder.querySelectorAll('.slider__buttons button'),
                    current_index = this_slider_instance.currentSlide;

                for (let i = 0; i < button_set.length; i += 1) {
                    if (current_index === i) {
                        button_set[i].classList.add('active');
                    } else {
                        button_set[i].classList.remove('active');
                    }
                }
            }
        }, 0);
    },

    setAriaAttributes(slider_index) {
        // Slides should be aria-hidden when not visible so slider has the same
        // functionality for mouse/keyboard/keyboard+aria (+ avoid position shift)
        setTimeout(() => {
            const this_slider_instance = slider_index === -1 ? rol_sliders.home_theme_slider
                    : rol_sliders.std_slider_instances[slider_index],
                this_holder_id = slider_index === -1 ? 'siema__home-theme' : `slider-${slider_index}`,
                slide_set = document.querySelectorAll(`#${this_holder_id} li`);
            let has_hidden_slides = false;

            for (let i = 0; i < slide_set.length; i += 1) {
                const this_slide = slide_set[i],
                    current_slide_plus_multi_across = this_slider_instance.currentSlide
                        + this_slider_instance.perPage;

                if (i >= this_slider_instance.currentSlide
                    && i < current_slide_plus_multi_across) {
                    this_slide.setAttribute('aria-hidden', false);
                    rol_sliders.setTabIndexes({ slide: this_slide, value: 0 });
                } else {
                    this_slide.setAttribute('aria-hidden', true);
                    rol_sliders.setTabIndexes({ slide: this_slide, value: -1 });
                    has_hidden_slides = true;
                }
            }

            rol_sliders.showHideKeyboardNav({ show: has_hidden_slides, this_holder_id });
        }, 0);
    },

    showHideKeyboardNav({ show, this_holder_id }) {
        // Hide the keyboard nav buttons when all slides are shown & no need to navigate
        const access_links = document.querySelectorAll(`#${this_holder_id} .focus-visible`);

        for (let i = 0; i < access_links.length; i += 1) {
            if (show) {
                access_links[i].classList.remove('hide-link');
            } else {
                access_links[i].classList.add('hide-link');
            }
        }
    },

    setTabIndexes({ slide, value }) {
        // Set tabindex to -1 for links & buttons which should be hidden
        const inner_links_buttons = slide.querySelectorAll('a, button');

        for (let i = 0; i < inner_links_buttons.length; i += 1) {
            inner_links_buttons[i].setAttribute('tabindex', value);
        }
    },

    checkHomeThemeToggle() {
        const home_theme_slider_el = document.getElementById('siema__home-theme');

        // If home theme slider exists on this page, setup or make setup/destroy depend
        // on screen width
        if (home_theme_slider_el) {
            const jump_to_section_visible = /non-slide-desktop/.test(home_theme_slider_el.classList);

            rol_sliders.setupHomeThemeNav(home_theme_slider_el);
            rol_sliders.home_theme_original_markup = home_theme_slider_el.innerHTML;

            if (jump_to_section_visible) {
                window.addEventListener('resize', () => {
                    rol_sliders.homeThemeToggleOnScreensize();
                });

                rol_sliders.homeThemeToggleOnScreensize();
            } else {
                rol_sliders.home_theme_slider = new Siema(rol_sliders.home_theme_slider_opts);
                rol_sliders.home_theme_slider_is_siema = true;
            }
        }
    },

    setupHomeThemeNav(home_theme_slider_el) {
        // Set up nav buttons once only (save these buttons with original markup, display none)
        const home_theme_slides = home_theme_slider_el.querySelectorAll('li');

        for (let i = 0; i < home_theme_slides.length; i += 1) {
            const this_slide = home_theme_slides[i];
            rol_sliders.addNextPrev(-1, this_slide, i, home_theme_slides.length);
        }
    },

    homeThemeToggleOnScreensize() {
        if (window.innerWidth > 767 && rol_sliders.home_theme_slider_is_siema) {
            // Destroy siema instance. Param restore markup from Siema does not work on IE11
            // So this is done manually
            rol_sliders.home_theme_slider.destroy(false);
            rol_sliders.home_theme_slider_is_siema = false;

            setTimeout(() => {
                rol_sliders.homeThemeRestoreMarkup();
            }, 0);
        } else if (window.innerWidth < 768 && !rol_sliders.home_theme_slider_is_siema) {
            // Setup / re-setup siema instance
            rol_sliders.home_theme_slider = new Siema(rol_sliders.home_theme_slider_opts);
            rol_sliders.home_theme_slider_is_siema = true;
        }
    },

    homeThemeRestoreMarkup() {
        document.getElementById('siema__home-theme').innerHTML = rol_sliders.home_theme_original_markup;
    },
};

module.exports = rol_sliders;
