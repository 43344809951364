const countable = require('../vendor/countable.min.js');

const rol_medium_pages = {
    words_per_minute: 200,

    setup() {
        // Expecting one detail area only - would need updating to handle multiple
        const detail_area = document.querySelector('.medium__content');

        if (detail_area) {
            countable.count(detail_area, rol_medium_pages.setReadingTime);
        }
    },

    setReadingTime(counter) {
        const reading_time = Math.ceil(counter.words / rol_medium_pages.words_per_minute),
            reading_time_span = document.getElementById('reading-time');

        if (reading_time_span) {
            reading_time_span.innerHTML = `${reading_time} min read`;
        }
    },
};

module.exports = rol_medium_pages;
